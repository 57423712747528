/* .book-demo-container {
  display: flex; justify-content: center; align-items: center; min-height: 70vh; background-color: #f7f7f7; padding: 20px;
  flex-direction: column;
} */
.book-demo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding: 20px;
  flex-direction: column;
  background-image: url('../Assets/happy\ customer.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff; /* Ensures text is visible against the background */
}
.book-demo-form {
  width: 100%; max-width: 80%; padding: 40px;  border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); text-align: center;
  min-height: 55vh;
  background-color: rgb(247, 248, 249);
 

}
.form-title {
  grid-column: span 2; /* Span the full row in grid */
  text-align: center; margin-bottom: 20px;
}
.form-grid {
  display: grid; grid-template-columns: repeat(2, 1fr); gap: 16px;
}
.form-field {
  display: flex; flex-direction: column;
}
.custom-datepicker, .custom-timepicker {
  width: 100%; padding: 8px; margin-top: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 16px;
}
label { font-weight: bold; margin-bottom: 4px; }
.error-message{
  color: red;
}
.full-width {
  grid-column: 1 / -1; /* Spans across all columns */
}

.form-field label {
  color: black;
}

@media (max-width: 768px) {
  .book-demo-form {
    max-width: 95%; 
  }
  .form-grid {
    grid-template-columns: 1fr; 
  }
}

.footer{
  margin: 6rem 0rem;
  display: flex;
  margin-top: -5rem;
  margin-left: 5rem;
}