
.team-management-container {
    background-color: #f5f5f5; /* Light grey background */
    padding: 20px;
    height: 600px;
    border-radius: 10px; /* Smooth border radius */
    max-width: 1200px;
    margin: 0 auto; /* Center the component */
  }
  
  .team-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-block, .team-selection-block {
    flex: 1;
    background-color: #ffffff; /* White background for form sections */
    padding: 20px;
    margin-right: 20px;
    border-radius: 10px; /* Smooth border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .team-selection-block {
    margin-right: 0;
  }
  
  .form-block input,
  .form-block select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  
  .form-block button {
    background-color: #1976d2;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-block button:hover {
    background-color: #1565c0;
  }
  
  .members-container {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    max-height: 350px; /* Fixed height but can expand */
    overflow-y: auto; /* Allows scroll if content exceeds height */
  }
  
  .members-container h4 {
    margin-bottom: 15px;
  }
  
  .team-members {
    list-style-type: none;
    padding: 0;
  }
  
  .team-members li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .team-members li:nth-child(odd) {
    background-color: #e0e0e0; /* Alternate row colors */
  }
  
  @media screen and (max-width: 768px) {
    .team-block {
      flex-direction: column;
    }
  
    .form-block, .team-selection-block {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .team-card {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .team-card:hover {
    transform: scale(1.05);
  }
  
  .team-card h5 {
    margin: 0;
  }
  

  .manage-team {
    position: relative; /* Ensure this div serves as a positioning context */
    text-align: right; /* Align content inside the div to the right */
    padding: 10px; /* Optional: Add padding for spacing */
}

.manage-team button {
    background-color: #007bff; /* Button background color */
    color: white; /* Text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Add padding for size */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change cursor on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.manage-team button:hover {
    background-color: #0056b3; /* Darker background on hover */
}